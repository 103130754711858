.basic-info-container {
    .header2 {
        margin-bottom: 5px;
    }
    .basic-info-list {
        padding: 0 10px;
    }

    .module-text-row {
        &:not(:last-child) {
            border-bottom: 1px solid rgba(233, 230, 230, 0.623);
        }

        height: 30px;
        line-height: 30px;
        margin-top: 6px;
        margin-bottom: 6px;
        padding-left: 20px;
        padding-right: 20px;
        display: flex;
        justify-content: space-between;
    }
}