.chronic-container {
    padding: 0 10px; 
    .chronic-condition {
        position: relative;
        .condition-row {
            display: flex; 
            .condition-code {
                font-family: "Oswald", sans-serif;
                font-weight: 400;
                font-size: 1.1em;
                text-transform: uppercase;
                min-width: 60px;
                min-height: 60px;
                border-radius: 30px;
                line-height: 60px;
                margin-right: 20px;
                display: flex;
                justify-content: center;
                .code-background {
                    position: absolute;
                    top: 5px;
                    min-height: 68px;
                    max-height: 68px;
                    min-width: 68px; 
                    max-width: 68px;
                    fill: white; 
                }
            }
        }

        .translation {
            position: absolute;
            bottom: 9px;
            font-size: 0.85em;          

        }

        .diagnosis-date {
            margin-left: auto;
            font-size: 0.8em;
            color: #e0dbdbdc;
            position: absolute;
            top: 10px; 
            right: 30px;
        }
    }
}