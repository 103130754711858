// Application wide styling, when things flesh out structure more when needed

// CSS vars 

:root {
    --hippo-border-bottom: 2px solid #807e7e33;

    // Basic
    --c-white: #fff;
    --c-black: #000;

    // Greys
    --c-ash: #eaeef6;
    --c-charcoal: #a0a0a0;
    --c-void: #141b22;

    // Beige/Browns
    --c-fair-pink: #FFEDEC;
    --c-apricot: #FBC8BE;
    --c-coffee: #754D42;
    --c-del-rio: #917072;

    // Greens
    --c-java: #1FCAC5;

    // Purples
    --c-titan-white: #f1eeff;
    --c-cold-purple: #a69fd6;
    --c-indigo: #6558d3;
    --c-governor: #4133B7;

    --c-hippo-red: #be6464;
    --c-hippo-blue: #177db5;
    --c-hippo-green: #00B0B9;

    // icon size: 
    --c-icon-size: 26px; 
}

html {
    // background-color: #e9b4b41a;
    background: radial-gradient(circle at 12.3% 19.3%, #D8E1EA 0%, #BFD1E5 100.2%);
    height: 100vh;
}

body {
    // OBS!! This affects the whole application rendering

    color: #595959;
    margin: 0;
    font-family: "Merriweather Sans", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-size: 14px; 
}

.hippo-module {
    background: #177db5;
    width: auto;
    min-height: 100%;
    max-height: 100%;
    border-radius: 20px;
    padding: 0;

    box-shadow: 0px 0px 8px 0px #00000033;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    position: relative;
    color: rgb(235, 235, 235);

    .res-logo {
        max-width: 50px;
        position: absolute;
        right: 20px;
        top: 20px;
    }

    .hippo-source {
        background: #F9F9F9;
        height: 50px;
        min-height: 50px;
        margin-top: auto;
        border-bottom-left-radius: 20px;
        border-bottom-right-radius: 20px;
        line-height: 50px;
        display: flex;


        .source-txt {
            margin-right: auto;
        }

        .source-logo {
            object-fit: contain;
            max-width: 50px;
            max-height: 50px;
            margin-right: 20px;
        }
    }

    h2 {
        font-family: "Oswald", sans-serif;
        font-weight: 400;
        margin: 0;
        font-size: 24px;
        background-color: #177db5;
        color: rgb(235, 235, 235);
        padding-top: 30px;
        padding-left: 30px;
        padding-bottom: 30px;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;

    }

    .module-icon {
        min-width: var(--c-icon-size);
        max-width: var(--c-icon-size);
        min-height: var(--c-icon-size);
        max-height: var(--c-icon-size);
        position: absolute;
        top: 40px;
        right: 30px;
    }
}

// charts look wonky with box shadow
.recharts-wrapper {
    &.hippo-list-item {
        box-shadow: none;

    }
}

.recharts-responsive-container {
    margin-left: auto;
    margin-right: auto;
    padding: 0 15px; 
}

.hippo-charts-tooltip {
    background-color: white;
    border-radius: 8px;
    color: var(--c-hippo-blue);
    .tooltip-header {
        background-color: var(--c-hippo-green);
        color: white;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 12px;
    }
    .tooltip-content {
        display: flex;
        flex-direction: column;
        padding: 12px;

        div {
            &.systolic {
                color: var(--c-indigo);
            }
            &.average {
                color: var(--c-indigo);
            }
            display: flex;
            justify-content: space-around;
        }
    }
}


.hippo-logo {
    color: white; 
    font-family: 'Poppins';
    line-height: 16px;
    font-size: 1.2em;
    .hippo-hl {
        color: #00B0B9;
        font-weight: 550;
    }
}



.hippo-list-item {
    background: none;
    min-height: 60px;
    padding: 9px;
    padding-left: 20px;
    padding-right: 20px;

    &:not(:last-child) {
        border-bottom: 1px solid #e9e6e69f; 
    }
}

