.reference-range-container {
    position: relative;
    min-width: 200px;
    height: 40px;

  .reference-range-bar {
    position: absolute;
    top: 20px;
    height: 5px;
    background-color: lightgreen;

    &.outside-range {
        background-color: orange;
        border-radius: 2px;
    }
  }
  
  .value-indicator {
    position: absolute;
    top: 14px;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: white;

    .inner-value-circle {
        margin-top: 3px;
        margin-left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--c-hippo-blue);
    }
  }

  .range-tick {
    position: absolute;
    top: 18px;
    width: 2px;
    height: 10px;
    background-color: white;
  }
}

.lab-results {
    .date-selector {
      position: absolute;
      background-color: var(--c-hippo-blue);
      border: 1px solid var(--c-white);
      color: var(--c-white);
      font-size: 12px;
      padding: 5px;
      border-radius: 5px;
      width: 110px;
      top: 37px;
      left: calc(max(50% - 55px, 210px));

      &:hover {
          cursor: pointer;
          color: white;
      }
    }

    .lr-container {
      padding: 0 10px; 
      overflow-y: auto;

      .lab-entry {
        position: relative;

          .lab-entry-container {
              display: flex;
              padding: 10px 10px 10px 10px;
              flex-direction: column;

              &:hover {
                background-color: #176eb5;
                border-radius: 7px;
                cursor: pointer;
              }

              .lab-row {
                display: flex;
                flex-direction: row;

                .lab-results {
                  display: flex;
                  bottom: 7px;

                  div:first-child {
                      margin-right: 3px; 
                  }
                  .red {
                      color: var(--c-hippo-red)
                  }
                }
              
                .lab-date {
                    font-size: 0.8em;
                    color: #ebebebdc;
                    margin-left: auto;
                }
              
                .lab-reference-range {
                    font-weight: 300;
                    padding-left: 7px;
                    color: #ebebebdc;
                }
              }
              
          }
      }
    }
}

.lab-results-chart {
  animation: fadeIn 0.1s linear;

  .close-icon {
    &:hover {
      cursor: pointer;
      filter: brightness(80%);
    }
  }
}


@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}