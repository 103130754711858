.epi-container {
    min-width: 100vw;

    .epi-header {
        width: calc(100% - 30px);
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        margin-left: 30px;

    }

    .epi-button {
        margin-right: 30px;
        color: #fff;
        text-transform: upper;
        background-color: #960018bb;
        border: 0;
        border-radius: 3px;
        font-weight: 300;
        height: 30px;
        margin-top: 20px;
        width: 110px;
        cursor: pointer; 
    }

    .epi-wizard {
        display: flex;
        justify-content: space-around;
        width: 50%;
        margin-left: auto;
        margin-right: auto;

        .wizard-step {
            opacity: 0.5;

            padding: 10px 20px 10px 20px;
            cursor: pointer;
            transition: all 0.5s ease-out;
            border-bottom: 2px solid transparent;

            &.active {
                opacity: 1;
                color: var(--c-hippo-red);
                border-bottom: 2px solid var(--c-hippo-red);
            }
        }

    }

    .epi-navigator {
        display: flex;
        justify-content: center;
        margin-top: 30px;

        :first-child {
            margin-right: 10px;
        }

        :last-child {
            margin-left: 10px;
        }

        .epi-nav-bt {
            width: 125px;
            height: 30px;
            border: 0;
            background-color: transparent;
            font-weight: 600;
            color: var(--c-hippo-red);
            opacity: 0.6;

            &:hover {
                opacity: 1;
                cursor: pointer;
            }
        }
    }

    .epi-carousel {
        display: flex;
        overflow-x: hidden;
        padding: 30px;

        .epi-carousel-item {
            min-width: 100vw;
            width: 100vw;
            display: flex;
            flex-direction: column;
            align-items: center;
            transition: all 0.5s ease-out;
        }

        .epi-header-small {
            font-size: 1.2em;

        }

        .epi-medication {}

        .epi-allergies {}

        .epi-operations {}

        .epi-family-history {}

        .epi-list {
            display: flex;
            flex-direction: column;
            min-width: 400px;
                width: 400px; 
            .epi-list-item {
                &:not(:first-child) {
                    margin-top: 10px; 
                }
                height: 30px;
                line-height: 30px;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid var(--c-hippo-red);
            }
            .epi-list-empty {
                display: flex;
                justify-content: center;
            }
        }
    }

}