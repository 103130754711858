.home-container {
    padding: 20px 50px 20px 50px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .latest-container,
    .faq-container {
        flex: 1; 
        min-width: 300px;
    }

    h1, h2 {
        font-family: Oswald, sans-serif;
        font-size: 24px;
        font-weight: 400;
        color: var(--c-hippo-blue);
    }
    h1 {
         font-size: 36px; 
    }

    .szh-accordion {
        border-bottom: 1px solid #ccc;

        &__item {
            margin-bottom: 8px;

            .accordion-text {
                padding: 5 25px;
                border-right: 2px solid var(--c-hippo-blue);
                border-left: 2px solid var(--c-hippo-blue);
                color: var(--c-hippo-blue);
            }
            
            &-btn {
                cursor: pointer;
                display: flex;
                align-items: center;
                width: 100%;
                margin: 0;
                padding: 8px 14px;
                font-size: 1rem;
                font-weight: 400;
                text-align: left;
                background-color: transparent;
                
                border: 2px solid var(--c-hippo-blue);
                border-radius: 18px;
                color: var(--c-hippo-blue);
                font-family: "Oswald", sans-serif;
                letter-spacing: 0.5;

                .chevron-down {
                    --chevron-stroke-color: var(--c-hippo-blue);
                    margin-left: auto;
                    transition: transform 0.25s cubic-bezier(0, 0, 0, 1); 
                }

                &:hover {
                    background-color: var(--c-hippo-blue);
                    color: #fff;
                    .chevron-down {
                        --chevron-stroke-color: #fff;
                    }
                }
            }

            &-content {
                transition: height 0.25s cubic-bezier(0, 0, 0, 1);
            }

            &-panel {
                padding: 1rem;
            }

            &--expanded {
                .szh-accordion__item-btn {
                    background-color: var(--c-hippo-blue);
                    color: #fff;
                }
                .chevron-down {
                  transform: rotate(180deg);
                  --chevron-stroke-color: #fff;
                }
            }
        }
    }

    @media (min-width: 768px) {
      flex-direction: row; 

      .latest-container {
          flex: 0 0 40%; 
      }

      .faq-container {
          flex: 0 0 55%; 
      }
    }

    @media (max-width: 767px) {
        flex-direction: column;
    }
}