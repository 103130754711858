.module-container {
    position: relative;
    border-radius: 20px;
    
    &.editing {
        //box-shadow: 10px 16px 15px 0px rgba(0,0,0,0.49);
        opacity: 0.7!important;
        
        cursor: move;
    }

    .expand-button {
        position: absolute;
        bottom: 10px;
        left: 20px;
        z-index: 10; 
        background-color: rgba(255, 255, 255, 0);
        border: none;
        padding: 5px 10px;
        cursor: pointer;
        color: rgb(75, 75, 75);

        &:hover {
            color: rgb(24, 23, 23);
        }
    }
}

.dnd-container {
    padding: 20px;

    // TODO: create grids for breakpoints (mobile)
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(450px, 1fr));
}