.prescriptions-container {
    padding: 0 10px; 
    .prescription-entry{
        position: relative;
        .prescription-name {
            font-weight: 400;
        }
        .prescription-dosage {
            font-size: 0.9em;
            position: absolute;
            max-width:100%;
            bottom:9px;
        }
        .prescription-date {
            font-size: 0.8em;
            color: #ebebebdc;
            margin-left: auto;
        }
    }
}