.signin-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50px;

    .signin-header {
        display: flex;
        justify-content: center;

        .logo-circle {
            background: var(--c-hippo-blue);
            width: 250px;
            height: 250px;

            display: flex;
            flex-direction: column;
            align-items: center;

            .hippo-logo {
                height: 30px;
            }
        }
    }


    // Amplify customization as per https://ui.docs.amplify.aws/react/connected-components/authenticator/customization

    [data-amplify-authenticator] {
        --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10);
        --amplify-components-authenticator-router-border-width: 0;
        --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
        --amplify-components-button-primary-background-color: var(--amplify-colors-neutral-100);
        --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--c-hippo-green);
        --amplify-components-tabs-item-active-border-color: var(--c-hippo-green);
        --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
        --amplify-components-tabs-item-active-color: var(--c-hippo-blue);
        --amplify-components-button-link-color: var(--amplify-colors-purple-80);


        .amplify-input {
            background: #ffffff;
        }

        .amplify-button {
            background: #ffffff;
            color: var(--c-hippo-blue);
        }

        .amplify-label {
            color: var(--c-ash);
        }

        .amplify-tabs__panel {
            background: var(--c-hippo-blue);
            min-height: 684px;
        }

        [data-amplify-footer] {
            margin-top: 70px;
        }
      }

}