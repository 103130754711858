.dropdown {
    position: relative;
    display: inline-block;
    margin-left: auto;

    .dropdown-menu {
        position: absolute;
        top: 100%;
        right: -10;
        background: var(--c-hippo-green);
        
        box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
        z-index: 1000;
        display: flex;
        flex-direction: column;
        min-width: 150px;
        padding: 10px 0;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .dropdown-item {
        padding: 10px 15px;
        cursor: pointer;
        white-space: nowrap;
        transition: background 0.2s;
        font-size: 14px;
        color: var(--c-ash);

        &:hover {
            color: #176eb5;
        }
    }
}