.hippo-nav {
    display: flex;
    background-color: var(--c-hippo-blue);
    //background: linear-gradient(to bottom right, rgba(19, 102, 222, 1),rgba(0, 177, 185, 1) );
    min-height: 70px;
    max-height: 70px;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0 0 4px rgba(0, 0, 0, .25);
    position: relative;
    
   
    .res-logo {
        max-height: 50px;
        line-height: 70px;
        margin-left: 20px;
        min-width: 50px; 
    }

    .hippo-logo {
        color: white; 
        font-family: 'Poppins';
        line-height: 16px;
        font-size: 1.2em;
        .hippo-hl {
            color: #00B0B9;
            font-weight: 550;
        }
    }

    .hippo-links {
        display: flex;
        padding-left: 10px;
        a {
            &:first-of-type {
                margin-left: 30px;
            }
            margin-left: 10px;
            color: white;
            text-decoration: none;

            &:hover {
                color: #00B0B9;
            }
        }
    }

    .hippo-controls {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: auto;
        margin-right: 50px;
        gap: 20px;

        .language-selector {
            background-color: var(--c-hippo-blue);
            border: 1px solid var(--c-white);
            color: var(--c-white);
            font-size: 12px;
            padding: 5px;
            border-radius: 3px;
            
            &:hover {
                cursor: pointer;
                color: white;
            }
        }
    
        .signout-bt {
            color: var(--c-white);
            background: none;
            border: none;
            font-size: 12px;

            display: flex;
            align-items: center;

            &:hover {
                cursor: pointer;
                filter: invert(55%) sepia(31%) saturate(5627%) hue-rotate(147deg) brightness(94%) contrast(101%);
            }

            .signout-icon {
                max-height: 18px;
                margin-right: 5px;
            }
        }
    }

    .bt-add-view {
        transition: all 0.3s ease-in;
        color: white;
        border: 2px solid white;
        min-height: 30px;
        min-width: 30px;
        border-radius: 30px;
        display: flex;
        line-height: 30px;
        justify-content: center;
        font-size: 1.6em;
        position: absolute;
        left: 45%;
        top: 35px;
        transform: translate(-50%, -50%);

        &:hover {
            cursor: pointer;
            border: 2px solid #00B0B9;
            color: white;
            background-color: #00B0B9;
        }
    }

    .apt-controls {
        display: flex;
        justify-content: space-around;
        margin-left: auto;
        margin-right: 30px;

        .bt-apt {
            font-family: 'Roboto';
            background: none;
            height: 30px;
            border-radius: 5px;
            border: 1px solid rgb(112, 112, 112);
            padding-left: 10px;
            padding-right: 10px;

            &:hover {
                cursor: pointer;
                color: red;

            }

            &:not(:first-child) {
                // who's your daddy
                margin-left: 10px;
            }

            &.end-apt {
                color: white;
                background-color: #960018bb;
                font-weight: 300;
                border: 0;
                border-radius: 3px;
                text-transform: upper;

                &:hover {
                    color: red;
                    border-color: red;
                }
            }
        }

    }

}