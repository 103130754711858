.search-view-container {
    padding: 0 20px;

    .patient-search-input {
        height: 40px;
        border-radius: 4px;
        box-shadow: none;
        border: 0;
        width: 250px;
        padding: 0 10px;
        outline: none;
    }

    .patient-search-button {
        height: 40px;
        margin-left: 20px;
        border-radius: 4px;
        background-color: var(--c-hippo-blue);
        border: 0;
        color: whitesmoke;
        font-weight: 300;
        width: 125px;
        cursor: pointer;

        &:hover {
            background-color: var(--c-hippo-green);
        }

        transition: 0.16s ease-in all;
    }

    .view-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        overflow: hidden;
    }

    .grid-container {
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
        gap: 20px;
        width: 100%;
        margin-top: 34px;
        overflow-y: auto;
    }

    .patient-card {
        background-color: var(--c-hippo-green);
        color: whitesmoke;
        border-radius: 8px;
        padding: 20px;
        text-align: left;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        width: 100%;
        max-width: 400px;
        margin: 0 auto;
        min-height: 101px;
        cursor: pointer;

        .card-header {
            display: flex;
            justify-content: space-between;
            font-family: Oswald, sans-serif;

            .patient-name {
                font-size: 18px;
                margin-bottom: 10px;
            }
        
            .patient-ssn {
                font-size: 12px;
                color: whitesmoke;
                opacity: 0.8;
            }
        }

        .card-content {
            margin-bottom: 8px;
        }

        .card-highlights {
            border-top: 1px solid rgba(233, 230, 230, 0.623);           
            padding-top: 8px;
            display: flex;
            .card-warnings {
                .icon {
                    max-height: 30px;
                }
                display: flex;
            }
            .warnings-list {
                display: flex;
                padding-left: 4px;
                .warning-entry {
                    line-height: 30px;
                    &:not(:last-child):after {
                        content: ",";
                        margin-right: 2px;
                    }
                }
            }
        }
    }

   
}