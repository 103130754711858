.dashboard-container {
    .dashboard-taskbar {
        position: sticky;
        top: 0;
        z-index: 999;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: 50px;
        background-color: var(--c-hippo-green);
        padding: 0 20px 0 40px;
        box-shadow: 0px 2px 10px 0px rgba(0,0,0,0.30);

        .patient-identification {
            font-family: Oswald, sans-serif;
            font-size: 20px;
            font-weight: 300;
            color: white; 
        
        }

        .right-buttons {
            display: flex;
            align-items: center; 
            padding-right: 20px;

            .button { // general button stylings, used below ( .button; )
                display: flex;         
                justify-content: center; 
                align-items: center;

                color: white;
                font-size: 14px; 
                padding: 8px;
                border: none; 
                border-radius: 4px; 

                cursor: pointer; 
                
                max-width: 80px;
                min-width: 80px;
                margin-left: 20px;
            }

            .save-button {
                .button;
                

                &.disabled {
                    opacity: 0.7;
                    cursor: not-allowed;
                }

                &:hover {
                    &:not(.disabled) {
                        background-color: var(--c-hippo-blue);
                    }
                }
            }

            .cancel-button {
                .button;


                &:hover {
                    background-color: var(--c-hippo-red);
                }
            }
            
            .settings-icon {
                height: 34px;
                margin-top: 5px;
                margin-left: 50px;
    
                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .dashboard-header {
        padding-left: 20px;
    }
}