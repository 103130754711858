.allergies-container {
    .allergies-list {
        padding: 0 10px; 
    }
    .allergy-container {
        position: relative;

        .allergy-header {
            font-size: 0.99em;
        }
        .allergy-symptom {
            font-size: 0.8em;
            position: absolute;
            bottom: 9;
        }
    }
}